import robotImg from "../assets/image/robotHeart.png";
import flowersTop from "../assets/image/sorokaTop.png";
import flowersBottom from "../assets/image/sorokaBottom.png";

const Hello = ({ setStage }) => {
  const handleClick = () => {
    setStage("common");
  };

  return (
    <div className="content hello">
      <div className="titleBlock">
        <h2>Узнаем тебя</h2>
        <h1>Из тысячи</h1>
      </div>
      <div className="mainWrapper">
        <img className={`robot`} src={robotImg} alt="robot" />
        <img className={`flowersTop`} src={flowersTop} alt="flowersTop" />
        <img
          className={`flowersBottom`}
          src={flowersBottom}
          alt="flowersBottom"
        />
        <div className="mainBlock">
          <div className="text">
            <p>Привет! Нет никаких сомнений, что ты прекрасна и уникальна</p>
            <p>
              Позволь мне поразить тебя своими знаниями и получи персональное
              поздравление!
            </p>
          </div>
          <div className="controls">
            <button className="button buttonPrimary" onClick={handleClick}>
              Начать
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hello;
