/**
   {
    name: "А",
    docs: true,
    psd: true,
    department: "dev",
    experience: 1,
  },
 */

export const allGirls = [
  {
    department: 'Отдел управления проектами',
    name: 'Стрелец Екатерина',
    docs: true,
    psd: false,
    experience: '12',
    congratulation:
      'Дорогая Катя, с 8 Марта! Желаем тебе всегда выигрывать тендеры, осваивать новые рынки и добиваться всех своих целей!',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Буланцева Елена',
    docs: false,
    psd: true,
    experience: '8',
    congratulation:
      'С 8 Марта, дорогая Лена! Желаем тебе, чтобы твои идеи были такими же острыми, как нож, и такими же прекрасными, как цветок.',
    День: '18',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Кирилова Анна',
    docs: false,
    psd: true,
    experience: '8.67',
    congratulation:
      'Поздравляем с 8 Марта, очаровательная Аня! Пусть твои работы будут такими же прекрасными и неповторимыми, как ты сама. Желаем тебе творческой свободы и женского благополучия!',
    День: '8',
    spring: true,
  },
  {
    department: 'Финансы',
    name: 'Костикова Алиса',
    docs: true,
    psd: false,
    experience: '7.67',
    congratulation:
      'Дорогая Алиса, от лица всех мужчин-сотрудников компании Onpoint поздравляем тебя с Международным женским днем! Желаем тебе весеннего настроения, улыбок и исполнения самых заветных желаний!',
    День: '4',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Дряннова Мария',
    docs: false,
    psd: true,
    experience: '6.83',
    congratulation:
      'С праздником, дорогая Маша! Желаем тебе новых идей, смелых решений и воплощения всех твоих творческих задумок. Пусть 8 Марта станет для тебя днем вдохновения и радости!',
    День: '8',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Федорцова Екатерина',
    docs: true,
    psd: false,
    experience: '6.58',
    congratulation:
      'Твой дар находить общий язык с самыми капризными клиентами просто невероятен. С тобой даже самые сложные задачи становятся легкими. С 8 марта, Катенька!',
    День: '24',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Рудницкая Ирина',
    docs: true,
    psd: false,
    experience: '6.25',
    congratulation:
      'С 8 марта, дорогая Ира! Твоя внимательность к каждой детали заставляет нас гордиться тем, что ты в нашей команде, а твоя организованность - пример для всех нас!',
    День: '6',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Уездовская Юлия',
    docs: true,
    psd: false,
    experience: '6.25',
    congratulation:
      'Дорогая Юля! Ты терпеливо выдерживаешь каждый этап правок, сохраняя при этом оптимизм и улыбку, и всегда готова покорить любые зарубежные горизонты. С 8 марта!',
    День: '27',
    spring: true,
  },
  {
    department: 'Финансы',
    name: 'Ананьева Светлана',
    docs: true,
    psd: false,
    experience: '5.83',
    congratulation:
      'Дорогая Света, с праздником! Пусть этот день будет наполнен радостью, цветами и комплиментами. Ты заслуживаешь самого лучшего, и мы сделаем все, чтобы ты чувствовала себя счастливой и любимой.',
    День: '26',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Сапожникова Диана',
    docs: false,
    psd: true,
    experience: '4.5',
    congratulation:
      'С Международным женским днем, очаровательная Диана! Желем тебе, чтобы твои работы были такими же женственными и элегантными, как ты сама.',
    День: '30',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Баранова Ксения',
    docs: false,
    psd: true,
    experience: '4.5',
    congratulation: 'Кодим вместе, покоряем вершины, гладим кошку 24/7! С 8 Марта, Ксюша!',
    День: '20',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Карчевская Марина',
    docs: true,
    psd: false,
    experience: '4.83',
    congratulation:
      'Марина! С 8 марта тебя! Степа просил передать, что твоя обратная связь всегда на высоте, она помогает ему расти и совершенствоваться.',
    День: '19',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Новикова Елена',
    docs: true,
    psd: false,
    experience: '3.42',
    congratulation:
      'Твой энтузиазм и преданность делу вдохновляют нас всех. Ты просто мегамарафонец проектов. С праздником!',
    День: '30',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Корелякова Александра',
    docs: false,
    psd: true,
    experience: '3.42',
    congratulation:
      'Поздравляем с 8 Марта, прекрасная Саша! Пусть твои работы будут такими же яркими и сочными, как весенние тюльпаны.',
    День: '2',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Васильева Александра',
    docs: false,
    psd: true,
    experience: '3.42',
    congratulation: 'Пусть код будет чистым, баги редкими, а море - чистым. С праздником, Саша!',
    День: '2',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Рысухина Анна',
    docs: false,
    psd: true,
    experience: '3.5',
    congratulation:
      'С Международным женским днем, прекрасная Аня! Пусть твоя жизнь будет наполнена творчеством, вдохновением и счастьем. Желаю тебе успехов в дизайне и гармонии во всем!',
    День: '12',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Карнаухова Ольга',
    docs: false,
    psd: true,
    experience: '3.5',
    congratulation:
      'Прекрасная Оля, поздравляем тебя с 8 Марта! Желаю тебе, чтобы твои дизайны были такими же смелыми и оригинальными, как ты сама.',
    День: '31',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Мареева Анна',
    docs: true,
    psd: false,
    experience: '3.42',
    congratulation: 'Ты не просто коллега, ты наша звезда! С 8 Марта, дорогая Аня!',
    День: '6',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Кирилова Екатерина',
    docs: false,
    psd: true,
    experience: '2.67',
    congratulation:
      'Поздравляем с 8 Марта, прекрасная Катя! Пусть твои работы будут такими же стильными и элегантными, как ты сама. Желаем тебе успеха, признания и настоящего счастья!',
    День: '1',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Молькова Юлия',
    docs: false,
    psd: true,
    experience: '2.17',
    congratulation:
      'Дорогая Юля, поздравляем тебя с 8 Марта! Желаем тебе вдохновения, творческого подъема и реализации всех твоих амбиций в дизайне.',
    День: '29',
    spring: false,
  },
  {
    department: 'Финансы',
    name: 'Точилина Светлана',
    docs: true,
    psd: false,
    experience: '2.17',
    congratulation:
      'Твоя красота, обаяние и профессионализм покоряют всех вокруг. Ты не просто коллега, но и настоящий друг, на которого всегда можно положиться. С праздником, дорогая Света!',
    День: '19',
    spring: false,
  },
  {
    department: 'OCM',
    name: 'Алексеева Мария',
    docs: false,
    psd: false,
    experience: '2',
    congratulation:
      'Пусть этот весенний праздник принесет тебе море позитива, радостных эмоций и приятных сюрпризов. Ты этого заслуживаешь, дорогая Маша!',
    День: '12',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Соломатина Кристина',
    docs: false,
    psd: true,
    experience: '2',
    congratulation: 'С праздником 8 марта, Кристина! Желаем тебе вдохновения, креатива и успешных коммитов!',
    День: '23',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Томилина Алина',
    docs: false,
    psd: true,
    experience: '2.42',
    congratulation:
      'Ты делаешь мир лучше своими строчками кода, а iStock дрожит перед тобой. С 8 Марта, наша талантливая Алина!',
    День: '21',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Марцинюк Яна',
    docs: true,
    psd: false,
    experience: '2.42',
    congratulation:
      'Твоя способность быстро ориентироваться в проектах и находить эффективные решения поражает. Дорогая Яна, с праздником 8 марта!',
    День: '17',
    spring: false,
  },
  {
    department: 'Отдел разработки новых продуктов',
    name: 'Азарова Алина',
    docs: true,
    psd: false,
    experience: '2.33',
    congratulation: 'С 8 марта! Желаем тебе, прекрасная Алина, работать с лучшими КОЛами планеты!',
    День: '10',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Малкова Ирина',
    docs: false,
    psd: true,
    experience: '2.25',
    congratulation:
      'Поздравляем с 8 Марта, очаровательная Ирина! Пусть твои работы будут такими же прекрасными и неповторимыми, как ты сама. Желаю тебе творческой свободы и открытых клиентов!',
    День: '30',
    spring: false,
  },
  {
    department: 'Финансы',
    name: 'Григорьева Анна',
    docs: true,
    psd: false,
    experience: '2.25',
    congratulation:
      'Твоя красота, обаяние и профессионализм покоряют всех вокруг. Ты не просто коллега, но и настоящий друг, на которого всегда можно положиться. С праздником, дорогая!',
    День: '14',
    spring: false,
  },
  {
    department: 'HR',
    name: 'Чибирева Светлана',
    docs: false,
    psd: false,
    experience: '2.92',
    congratulation:
      'Ты не просто специалист в области управления персоналом, но и настоящая волшебница, которая создает комфортную и гармоничную рабочую атмосферу в нашей компании. С 8 марта, дорогая Света!',
    День: '29',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Ерохина Мария',
    docs: true,
    psd: false,
    experience: '2.92',
    congratulation: 'Маша, с 8 марта! Ты как солнечный луч, который освещает наш офис и делает работу приятной.',
    День: '6',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Семёнова Валентина',
    docs: false,
    psd: true,
    experience: '2.92',
    congratulation: 'Пусть каждый твой проект будет шедевром, а каждый релиз - триумфом! С праздником, дорогая Тина!',
    День: '22',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Магакелян Яна',
    docs: true,
    psd: false,
    experience: '2.83',
    congratulation: 'Яна, твое умение работать в команде и поддерживать коллег бесценно. С праздником!',
    День: '27',
    spring: false,
  },
  {
    department: 'Отдел разработки новых продуктов',
    name: 'Протопопова Алена',
    docs: false,
    psd: false,
    experience: '2.83',
    congratulation: 'С 8 марта, Алена! Пусть твоя креативность и энтузиазм продолжают вдохновлять нас на новые высоты.',
    День: '6',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Крюкова Мария',
    docs: false,
    psd: true,
    experience: '1.67',
    congratulation:
      'Маша! Спасибо за твой ум, талант и преданность делу. С Международным женским днем, и пусть меланома будет только на экране!',
    День: '8',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Новак Анастасия',
    docs: true,
    psd: false,
    experience: '1.42',
    congratulation:
      'С 8 марта, Настя! Ты не просто девушка, ты суперженщина, которая справляется со всеми задачами с легкостью.',
    День: '4',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Гурина Анна',
    docs: true,
    psd: false,
    experience: '1.33',
    congratulation:
      'С Международным женским днем! Твоя поддержка и понимание делают нашу работу не только эффективной, но и приятной. Ты настоящий лучик света в нашей команде!',
    spring: true,
  },
  {
    department: 'HR',
    name: 'Егорова Александра',
    docs: false,
    psd: false,
    experience: '1.33',
    congratulation:
      'Дорогая HR-леди, с 8 марта! Ты как опытный рекрутер, всегда находишь идеальных кандидатов для нашей команды. Желаем тебе сегодня такого же успеха в поиске идеального отдыха и развлечений!',
    День: '17',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Геворкян Юлия',
    docs: true,
    psd: false,
    experience: '1.25',
    congratulation: 'Юля, с 8 марта! Ты всегда готова помочь другим, даже когда у тебя много своей работы!',
    День: '23',
    spring: true,
  },
  {
    department: 'Отдел разработки новых продуктов',
    name: 'Паклина Полина',
    docs: false,
    psd: false,
    experience: '1.25',
    congratulation: 'Ты - движущая сила наших инноваций и пивных встреч. С 8 марта, Полина!',
    День: '10',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Тарасова Анастасия',
    docs: false,
    psd: true,
    experience: '1.25',
    congratulation: 'Желаем тебе гармонии в коде и в жизни. И чтобы Кузя был не Куся. С праздником, дорогая Настя!',
    День: '12',
    spring: false,
  },
  {
    department: 'OCM',
    name: 'Батищева Анастасия',
    docs: false,
    psd: false,
    experience: '2',
    congratulation:
      'В этот прекрасный день 8 Марта, мы, твои коллеги из Onpoint, хотим выразить наше восхищение твоей целеустремленностью, умом и очарованием. Пусть этот день будет наполнен радостью и вдохновением!',
    День: '24',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Белозерова Ксения',
    docs: false,
    psd: true,
    experience: '1.08',
    congratulation:
      'Дорогая Ксения, с 8 Марта! Пусть твои клиенты всегда будут открыты к твоим креативным идеям, а проекты будут увлекательными и вдохновляющими.',
    День: '12',
    spring: true,
  },
  {
    department: 'Отдел дизайна',
    name: 'Мелник Карина',
    docs: false,
    psd: true,
    experience: '1.08',
    congratulation:
      'Поздравляем с 8 Марта, талантливая художница! Пусть твои работы будут такими же уникальными и неповторимыми, как отпечатки пальцев.',
    День: '12',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Соболева Диана',
    docs: false,
    psd: true,
    experience: '1.08',
    congratulation:
      'Поздравляю с 8 Марта, талантливая Диана! Пусть твои работы будут такими же изысканными и утонченными, как орхидеи.',
    День: '27',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Чикунова Кристина',
    docs: false,
    psd: true,
    experience: '1.08',
    congratulation:
      'С Международным женским днем, Кристина! Твоя способность превращать креативные идеи в потрясающие дизайны поражает. Пусть твоя страсть к дизайну продолжает сиять.',
    День: '25',
    spring: true,
  },
  {
    department: 'Отдел разработки новых продуктов',
    name: 'Жданкина Маргарита',
    docs: false,
    psd: false,
    experience: '1.08',
    congratulation:
      'Поздравляем тебя с 8 марта, Маргарита! Пусть твой энтузиазм и талант продолжают вдохновлять нас на новые высоты в омникальной коммуникации. А также чтобы твои танцы были всегда такими же огненными!',
    День: '23',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Зерникова Алина',
    docs: true,
    psd: false,
    experience: '1',
    congratulation:
      'Алина, с 8 марта! Пусть даже самые сложные и нудные проекты превратятся в простые и без правок) Счастья и сговорчивых клиентов!',
    День: '19',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Шестаковская Алена',
    docs: true,
    psd: false,
    experience: '1.58',
    congratulation:
      'С праздником, Алёна! Твой дар находить общий язык даже с самыми капризными клиентами просто невероятен. Ты дипломат от Бога и совсем не арбузер!',
    День: '2',
    spring: true,
  },
  {
    department: 'OCM',
    name: 'Кузьменкова Анастасия',
    docs: false,
    psd: false,
    experience: '1.5',
    congratulation:
      'Для нашей потрясающей коллеги из Onpoint, в этот Международный женский день мы желаем тебе счастья, успеха и исполнения всех твоих желаний. Пусть твой путь будет усеян розами, а каждый день приносит улыбку!',
    День: '23',
    spring: true,
  },
  {
    department: 'HR',
    name: 'Лопатова Елена',
    docs: false,
    psd: false,
    experience: '1.5',
    congratulation:
      'С 8 марта, наша дорогая HR-фея Лена! Ты создаешь такую теплую и гостеприимную рабочую атмосферу, что каждый сотрудник чувствует себя как дома. Желаем тебе сегодня такого же домашнего уюта и радости!',
    День: '12',
    spring: false,
  },
  {
    department: 'Медицинский отдел',
    name: 'Дроздова Наталья',
    docs: false,
    psd: false,
    experience: '1.83',
    congratulation:
      'С Международным женским днем, Наталья! Твои объяснения — как бальзам на душу для тех, кто боится медицинских терминов. Спасибо за то, что помогаеешь нам понять наше здоровье и принимать осознанные решения. Желаем тебе всего самого прекрасного и вдохновляющего!',
    День: '6',
    spring: true,
  },
  {
    department: 'Отдел дизайна',
    name: 'Гавриш Галина',
    docs: false,
    psd: true,
    experience: '0.42',
    congratulation:
      'С Международным женским днем, Галя! Твои креативные идеи и страсть к дизайну делают мир прекраснее. Пусть твой талант продолжает процветать.',
    День: '19',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Кащеева Полина',
    docs: false,
    psd: true,
    experience: '0.42',
    congratulation: 'Пусть твои идеи будут блестящими, а решения - элегантными. С 8 Марта, наша гениальная Полина!',
    День: '2',
    spring: true,
  },
  {
    department: 'Отдел разработки новых продуктов',
    name: 'Белкина Яна',
    docs: false,
    psd: false,
    experience: '1',
    congratulation:
      'С международным женским днем, Яна! Твои таланты и навыки делают нас сильнее как команду. А твое умение укрощать даже самый говняный код - это нечто elképesztő',
    День: '5',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Кведорелис Елена',
    docs: true,
    psd: false,
    experience: '0.25',
    congratulation: 'Лена, с 8 марта! Твоя способность превращать сложные проекты в простые заслуживает аплодисментов.',
    День: '24',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Мустафина Диана',
    docs: true,
    psd: false,
    experience: '0.25',
    congratulation:
      'Дорогая Диана! С праздником тебя! Ты всегда находишь время для обучения и развития, что делает тебя еще более ценной для нашей команды.',
    День: '26',
    spring: true,
  },
  {
    department: 'Отдел дизайна',
    name: 'Салтыкова Виктория',
    docs: false,
    psd: true,
    experience: '0',
    congratulation:
      'Поздравляем с Международным женским днем, талантливая Вика! Желаем тебе, чтобы вдохновение всегда было рядом, клиенты ценили твой труд, а работа приносила удовлетворение.',
    День: '27',
    spring: false,
  },
  {
    department: 'Финансы',
    name: 'Родионова Екатерина',
    docs: true,
    psd: false,
    experience: '0.08',
    congratulation:
      'Пусть этот праздник станет для тебя поводом еще раз почувствовать, как мы тебя ценим и уважаем. Ты уже незаменимая часть нашей команды! С 8 марта, дорогая Катя!',
    День: '4',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Фиоктистова Варвара',
    docs: false,
    psd: true,
    experience: '1.75',
    congratulation:
      'Ты с легкостью разработала бы даже код Да-Винчи. С праздником, любимая Варвара! Новых высот и никогда не заканчивающейся энергии!',
    День: '23',
    spring: false,
  },
  {
    department: 'Отдел разработки новых продуктов',
    name: 'Зайцева Маргарита',
    docs: false,
    psd: false,
    experience: '0.25',
    congratulation: 'С 8 марта, Маргарита! Желаем тебе новых высот и достижений в Onpoint.',
    День: '13',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Куштапова Елизавета',
    docs: true,
    psd: false,
    experience: '0.25',
    congratulation:
      'C 8 марта, Лиза! Твое умение быстро адаптироваться к изменениям и находить новые решения впечатляет.',
    День: '20',
    spring: false,
  },
  {
    department: 'OCM',
    name: 'Гарбузова Екатерина',
    docs: false,
    psd: false,
    experience: '0.25',
    congratulation:
      'В этот Международный женский день мы желаем тебе всего самого лучшего. Пусть твоя карьера процветает, а личная жизнь будет наполнена любовью и счастьем. С 8 Марта!',
    День: '10',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Ковырзина Екатерина',
    docs: false,
    psd: true,
    experience: '0.17',
    congratulation: 'Желаю тебе успехов в карьере и счастья в личной жизни. С 8 Марта, дорогая Катя! С праздником!',
    День: '4',
    spring: false,
  },
  {
    department: 'Финансы',
    name: 'Поп Анастасия',
    docs: true,
    psd: false,
    experience: '0.17',
    congratulation:
      'В этот особенный день мы хотим выразить нашу благодарность за твой вклад в Onpoint. Твоя страсть, энтузиазм и стремление к совершенству делают нашу компанию лучше. С 8 Марта!',
    День: '23',
    spring: false,
  },
  {
    department: 'Медицинский отдел',
    name: 'Рылина Дарья',
    docs: false,
    psd: false,
    experience: '0.17',
    congratulation:
      'Дорогая Даша, поздравляем тебя с 8 Марта! Ты — настоящая волшебница, которая превращает медицинские загадки в ясные ответы. Пусть твоя интуиция и знания всегда ведут нас по миру медицинского контента. Счастья, любви и благодарных клиентов!',
    День: '16',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Дмитриева Ирина',
    docs: false,
    psd: true,
    experience: '0.08',
    congratulation:
      'Пусть ваши коммиты будут приняты с первого раза, а деплои всегда будут успешными. С праздником, дорогая Ирина!',
    День: '20',
    spring: true,
  },
  {
    department: 'Медицинский отдел',
    name: 'Буканова Дарья',
    docs: false,
    psd: false,
    experience: '0.08',
    congratulation:
      'С праздником весны, Даша! Спасибо, что всегда и проясняешь наши медицинские недоумения и делаешь сложные вещи понятными. Желаем тебе здоровья, счастья и успехов в нашем благородном деле!',
    День: '15',
    spring: false,
  },
  {
    department: 'Отдел разработки новых продуктов',
    name: 'Дудинская Екатерина',
    docs: false,
    psd: false,
    experience: '0.33',
    congratulation: 'С 8 марта, Катя! Твои идеи - семена, из которых вырастают инновационные продукты.',
    День: '13',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Малышкина Юлия',
    docs: true,
    psd: false,
    experience: '0.33',
    congratulation:
      'С 8 Марта, дорогая Юля! Твоя обратная связь – это золото. Она всегда конструктивна, точна и помогает нам расти.',
    День: '17',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Нечаева Мария',
    docs: true,
    psd: false,
    experience: '0.33',
    congratulation:
      'С 8 Марта, дорогая Маша! Твоя креативность и нестандартное мышление делают каждый проект уникальным. Ты настоящий художник в своей области.',
    День: '17',
    spring: false,
  },
  {
    department: 'HR',
    name: 'Шаталова Мария',
    docs: false,
    psd: false,
    experience: '0.33',
    congratulation:
      'С 8 марта, наша HR-звездочка Маша! Ты всегда сияеешь своей добротой, профессионализмом и позитивным настроем. Желаем тебе сегодня такого же яркого и незабываемого праздника!',
    День: '21',
    spring: true,
  },
  {
    department: 'Отдел дизайна',
    name: 'Цеймах Анна',
    docs: false,
    psd: true,
    experience: '0.42',
    congratulation:
      'С 8 Марта, Аня! Твои уникальные дизайны вдохновляют и восхищают. Желаем тебе продолжать создавать шедевры, которые оставляют неизгладимое впечатление.',
    День: '25',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Семенова Юлия',
    docs: false,
    psd: true,
    experience: '0.42',
    congratulation:
      'С 8 Марта, Юля! Пусть твои проекты всегда будут отражением твоего уникального стиля и креативного видения.',
    День: '2',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Пак Анастасия',
    docs: false,
    psd: true,
    experience: '0.5',
    congratulation:
      'Дорогая Настя, поздравляем тебя с 8 Марта! Твоя способность находить красоту в обыденном и превращать ее в экстраординарное — дар.',
    День: '7',
    spring: false,
  },
  {
    department: 'OCM',
    name: 'Каданова Кристина',
    docs: false,
    psd: false,
    experience: '0.42',
    congratulation:
      'Ты делаешь нашу компанию лучше, а наш коллектив - дружнее. Спасибо тебе за все, дорогая! С Международным женским днем, дорогая Настя!',
    День: '22',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Канарева Валентина',
    docs: true,
    psd: false,
    experience: '0.58',
    congratulation: 'С 8 Марта, наша скоростная звезда! Твои проекты исчезают в мгновение ока, как будто их и не было.',
    День: '9',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Кривобокова Татьяна',
    docs: true,
    psd: false,
    experience: '0.58',
    congratulation:
      'С Международным женским днем, дорогая Танечка! Твоя способность выстраивать отношения с клиентами на высшем уровне делает нашу работу намного приятнее.',
    День: '20',
    spring: false,
  },
  {
    department: 'Отдел разработки новых продуктов',
    name: 'Ефимова Александра',
    docs: false,
    psd: false,
    experience: '0.58',
    congratulation: 'С 8 марта, любимая Саша! Пусть твой профессионализм и страсть продолжают сиять в Onpoint.',
    День: '29',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Печерская Виктория',
    docs: true,
    psd: false,
    experience: '0.58',
    congratulation:
      'С праздником, Вика! Твое позитивное отношение и энтузиазм создают отличную рабочую атмосферу для всех.',
    День: '29',
    spring: true,
  },
  {
    department: 'Отдел дизайна',
    name: 'Харламова Анастасия',
    docs: false,
    psd: true,
    experience: '0.5',
    congratulation:
      'Поздравляем с 8 Марта, Настя! Пусть каждый проект будет холстом для твоего творчества, а каждый клиент станет источником вдохновения.',
    День: '9',
    spring: true,
  },
  {
    department: 'Отдел разработки',
    name: 'Кривопалова Майя',
    docs: false,
    psd: true,
    experience: '0.5',
    congratulation: 'Пусть твои проекты будут масштабируемыми, а код - устойчивым. С праздником, дорогая Майя!',
    День: '25',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Краснова Анастасия',
    docs: true,
    psd: false,
    experience: '0.58',
    congratulation:
      'С Международным женским днем, дорогая Настя! Твое терпение в процессе правок заслуживает отдельной награды. Ты как скала, которая выдерживает любые штормы.',
    День: '18',
    spring: false,
  },
  {
    department: 'HR',
    name: 'Малофеева Татьяна',
    docs: true,
    psd: false,
    experience: '0.67',
    congratulation:
      'Дорогая HR-супергероиня Таня, с праздником! Ты всегда готова прийти на помощь сотрудникам, решая любые вопросы с молниеносной скоростью. Желаем тебе сегодня такого же быстрого и легкого дня, наполненного только приятными сюрпризами!',
    День: '26',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Шобогорова Влада',
    docs: true,
    psd: false,
    experience: '0.67',
    congratulation:
      'Влада, твоя улыбка и позитивное отношение делают каждый день лучше. С праздником 8 марта! Никогда не теряй своего позитива!',
    День: '8',
    spring: true,
  },
  {
    department: 'Медицинский отдел',
    name: 'Керекеша Анастасия',
    docs: false,
    psd: false,
    experience: '0.67',
    congratulation:
      'Дорогая Настя, с 8 Марта! Спасибо за то, что помогаешь нам разобраться в самых сложных медицинских вопросах. Желаем тебе счастья, здоровья и успехов в твоем благородном деле!',
    День: '24',
    spring: false,
  },
  {
    department: 'Отдел дизайна',
    name: 'Григорьева Мария',
    docs: false,
    psd: true,
    experience: '0.67',
    congratulation:
      'Поздравляем с 8 Марта, Маша! Желаем тебе смелых идей, креативных решений и воплощения всех твоих творческих мечтаний.',
    День: '6',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Скуратова Евгения',
    docs: true,
    psd: false,
    experience: '0.58',
    congratulation:
      'Женя, с 8 марта! Твое умение налаживать отношения с клиентами и выстраивать долгосрочное сотрудничество просто поражает.',
    День: '8',
    spring: false,
  },
  {
    department: 'Отдел разработки',
    name: 'Торопова Анастасия',
    docs: false,
    psd: true,
    experience: '0.75',
    congratulation:
      'Желаем тебе успешных релизов и счастливых пользователей крышесносного 3D. С праздником, наша любимая Настя! Пусть твоя энергия никогда не иссякнет!',
    День: '19',
    spring: false,
  },
  {
    department: 'OCM',
    name: 'Бочарова Галина',
    docs: false,
    psd: false,
    experience: '0.75',
    congratulation:
      'Твоя улыбка озаряет наш офис и делает каждый день ярче. Спасибо тебе за то, что ты есть! С 8 Марта, дорогая!',
    День: '6',
    spring: true,
  },
  {
    department: 'OCM',
    name: 'Следнева Анна',
    docs: false,
    psd: false,
    experience: '0.92',
    congratulation:
      'Дорогая, с 8 Марта! Твой интеллект и решимость вдохновляют. Пусть каждый твой проект будет шедевром, а успех будет твоим верным спутником.',
    День: '23',
    spring: false,
  },
  {
    department: 'HR',
    name: 'Васильева Дарья',
    docs: true,
    psd: false,
    experience: '2.3',
    congratulation:
      'Дорогая Даша, ты наш HR-ангел, с праздником! Ты всегда заботишься о благополучии сотрудников, создавая для нас все условия для комфортной работы. Желаем тебе сегодня такого же комфорта и блаженства!',
    День: '25',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Комлева Анастасия',
    docs: true,
    psd: false,
    experience: '0.83',
    congratulation:
      'Ты всегда готова выйти за рамки своих обязанностей, что говорит о твоей преданности делу и готовности помочь. С 8 марта, Настя!',
    День: '16',
    spring: false,
  },
  {
    department: 'Отдел разработки новых продуктов',
    name: 'Черникова Алина',
    docs: false,
    psd: false,
    experience: '1',
    congratulation:
      'Наша дорогая Алина, в этот прекрасный праздник мы хотим признать твою силу, красоту и интеллект. Ты являешься примером для подражания для всех нас и делаешь нашу компанию по-настоящему выдающейся. С 8 Марта!',
    День: '10',
    spring: true,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Хабарова Анастасия',
    docs: true,
    psd: false,
    experience: '0.92',
    congratulation:
      'Настя, с 8 марта! Твое внимание к деталям и стремление к совершенству гарантируют, что все проекты будут выполнены на самом высоком уровне.',
    День: '18',
    spring: true,
  },
  {
    department: 'Отдел дизайна',
    name: 'Паницкова Наталья',
    docs: false,
    psd: true,
    experience: '2.92',
    congratulation:
      'Твой энтузиазм и страсть к дизайну (и мемы) вдохновляют всех вокруг. С 8 марта, дорогая Наташа, днем, когда мы празднуем твою креативность и талант!',
    День: '3',
    spring: true,
  },
  {
    department: 'Отдел дизайна',
    name: 'Видякова Анастасия',
    docs: false,
    psd: true,
    experience: '0',
    congratulation:
      'С праздником, дорогая Настя! Пусть 8 Марта принесет тебе новые творческие идеи, вдохновение и признание твоих талантов. Удачи на новом месте)',
    День: '22',
    spring: true,
  },
  {
    department: 'Отдел разработки новых продуктов',
    name: 'Горийчук Дарья',
    docs: false,
    psd: false,
    experience: '0',
    congratulation:
      'С Международным женским днём, дорогая Даша! Твоя способность преодолевать трудности и достигать невероятных высот поражает. Желаю тебе новых побед и неиссякаемой энергии.',
    День: '24',
    spring: false,
  },
  {
    department: 'Отдел разработки новых продуктов',
    name: 'Мещерякова Дарья',
    docs: false,
    psd: false,
    experience: '0',
    congratulation: 'С 8 марта, Даша! Твоя изобретательность и дальновидность - движущая сила нашего успеха.',
    День: '14',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Великих Мария',
    docs: true,
    psd: false,
    experience: '0.42',
    congratulation:
      'С праздником, Маша! Твоя способность быстро и эффективно решать задачи вдохновляет всю команду. Ты настоящий мастер своего дела!',
    День: '10',
    spring: false,
  },
  {
    department: 'Отдел управления проектами',
    name: 'Зотова Анна',
    docs: true,
    psd: false,
    experience: '1.5',
    congratulation:
      'С 8 Марта, дорогая Аня! Твоя решимость и целеустремленность вдохновляют всех вокруг. Ты никогда не сдаешься и всегда добиваешься поставленных целей.',
    День: '22',
    spring: true,
  },
];
