import { useState, useRef } from "react";
import robotImg from "../assets/image/robotDrop.png";
import { ReactComponent as IconArrow } from "../assets/icons/arrow.svg";
import ScrollArea from "../components/ScrollArea/ScrollArea";
import useClickOutside from "../hooks/useClickOutside";
import flowersTop from "../assets/image/sorokaTop.png";
import flowersBottom from "../assets/image/sorokaBottom.png";

const departments = [
  "Отдел разработки",
  "Отдел дизайна",
  "Отдел управления проектами",
  "HR",
  "Финансы",
  "OCM",
  "Отдел разработки новых продуктов",
  "Медицинский отдел",
];

const DepartmentSelect = ({ girls, setGirls, setStage }) => {
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const dropRef = useRef(null);

  const handleClickDDItem = (event) => {
    const { depart } = event.target.dataset;
    setSelectedDepartment(depart);
  };

  const handleSubmitClick = () => {
    setGirls(girls.filter((girl) => girl.department === selectedDepartment));
    setStage("experience");
  };

  useClickOutside(isOpen, dropRef, toggle);

  const dropText = selectedDepartment || "Укажи свой отдел";

  return (
    <div className="content">
      <img className={`robot`} src={robotImg} alt="robot" />
      <img className={`flowersTop`} src={flowersTop} alt="flowersTop" />
      <img
        className={`flowersBottom`}
        src={flowersBottom}
        alt="flowersBottom"
      />
      <div className="mainBlock">
        <p className="title">Вот это да!</p>
        <div className="text">
          <p>
            Ты — настоящая тайна для меня, не могу даже угадать отдел, в котором
            ты работаешь.
          </p>
          <p>Придется попросить у тебя немного мне подсказать...</p>
        </div>
        <div className="controls">
          <div className="dropWrapper" ref={dropRef}>
            <button className="button" onClick={toggle}>
              <div className="button_main">{dropText}</div>
              <div className={"dropdownIconContainer"}>
                <IconArrow
                  className={`dropdownIcon ${isOpen ? "dropdownIconOpen" : ""}`}
                />
              </div>
            </button>
            {isOpen && (
              <div className={"dropContaner"} tabIndex={0} onClick={toggle}>
                <ScrollArea maxHeight={"180px"}>
                  {departments.map((depart) => {
                    const isChecked = selectedDepartment === depart;
                    return (
                      <div
                        className={`dropdownItem ${isChecked ? "active" : ""}`}
                        onClick={handleClickDDItem}
                        data-depart={depart}
                        key={depart}
                      >
                        {depart}
                      </div>
                    );
                  })}
                </ScrollArea>
              </div>
            )}
          </div>
          <button className="buttonPrimary" onClick={handleSubmitClick}>
            Продолжить
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepartmentSelect;
