import robotImg from "../assets/image/robotHapi.png";
import img1 from "../assets/results/1.jpg";
import img2 from "../assets/results/2.jpg";
import img3 from "../assets/results/3.jpg";
import img4 from "../assets/results/4.jpg";
import img5 from "../assets/results/5.jpg";
import img6 from "../assets/results/6.jpg";
import img7 from "../assets/results/7.jpg";
import img8 from "../assets/results/8.jpg";
import img9 from "../assets/results/9.jpg";
import img10 from "../assets/results/10.jpg";
import img11 from "../assets/results/11.jpg";
import img12 from "../assets/results/12.jpg";
import flowersTop from "../assets/image/sorokaTop.png";
import flowersBottom from "../assets/image/sorokaBottom.png";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
const Final = ({ girls }) => {
  const girlChampion = girls[0];

  const rndInt = randomIntFromInterval(0, 11);
  const img = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
  ][rndInt];

  const renderName = girlChampion?.name?.split(" ").reverse().join(" ");

  return (
    <div className="content">
      <div className="mainWrapper">
        <img className={`robot`} src={robotImg} alt="robot" />
        <img className={`flowersTop`} src={flowersTop} alt="flowersTop" />
        <img
          className={`flowersBottom`}
          src={flowersBottom}
          alt="flowersBottom"
        />
        <div className="mainBlock">
          <p className="title">{renderName}</p>
          <div className="text">
            <p>{girlChampion?.congratulation}</p>
          </div>
          <img className={`resultImg`} src={img} alt="result" />
        </div>
      </div>
    </div>
  );
};

export default Final;
