import { useState } from 'react';
import robotImg from '../assets/image/robotQuestion.png';
import flowersTop from '../assets/image/sorokaTop.png';
import flowersBottom from '../assets/image/sorokaBottom.png';

const commonQuestions = [
  {
    text: 'Работаешь ли ты с приложениями и актами?',
    value: 'docs',
  },
  {
    text: 'Сможешь ли засмартить объект в Photoshop?',
    value: 'psd',
  },
  {
    text: 'Хмм.. Твой день рождения весной?',
    value: 'spring',
  },
];

const Common = ({ girls, setGirls, setStage }) => {
  const [questionNumber, setQuestionNumber] = useState(0);

  const handleClick = (event) => {
    const { value, answer } = event.target.dataset;
    const answerYes = answer === 'yes';
    const nextQuestionNumber = questionNumber + 1;
    const filteredGirls = filterGirls(value, answerYes);

    setGirls(filteredGirls);
    if (nextQuestionNumber >= commonQuestions.length) {
      setStage('department');
    } else {
      setQuestionNumber(nextQuestionNumber);
    }
  };

  const filterGirls = (value, answerYes) => {
    return girls.filter((girl) => {
      return girl[value] === answerYes;
    });
  };

  const question = commonQuestions[questionNumber];
  return (
    <div className='content'>
      <div className='mainWrapper'>
        <img className={`robot`} src={robotImg} alt='robot' />
        <img className={`flowersTop`} src={flowersTop} alt='flowersTop' />
        <img className={`flowersBottom`} src={flowersBottom} alt='flowersBottom' />
        <div className='mainBlock'>
          <p>{question.text}</p>
          <div className='controls'>
            <button className='button buttonYes' data-value={question.value} data-answer={'yes'} onClick={handleClick}>
              Да
            </button>
            <button className='button buttonNo' data-value={question.value} data-answer={'no'} onClick={handleClick}>
              Нет
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Common;
