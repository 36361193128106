import { useState, useRef } from "react";
import { allGirls } from "../girls";
import ScrollArea from "../components/ScrollArea/ScrollArea";
import robotImg from "../assets/image/robotDrop.png";
import useClickOutside from "../hooks/useClickOutside";
import flowersTop from "../assets/image/sorokaTop.png";
import flowersBottom from "../assets/image/sorokaBottom.png";

const NameInput = ({ girls, setGirls, setStage }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectName, setSelectName] = useState("");
  const dropRef = useRef(null);

  const [hints, setHints] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectName("");
    setInputValue(value);
    if (value.length > 2) {
      const possibleGirls = allGirls.filter((girl) => {
        return girl.name.toLowerCase().includes(value.toLowerCase());
      });
      if (possibleGirls.length > 0) {
        setIsOpen(true);
        setHints(possibleGirls);
      } else setIsOpen(false);
    } else setIsOpen(false);
  };

  const handleNameClick = (event) => {
    const { name } = event.target.dataset;
    setSelectName(name);
    setInputValue(name);
    setIsOpen(false);
  };

  const handleSubmitClick = () => {
    if (selectName.length === 0) return;
    setGirls(allGirls.filter((girl) => girl.name === selectName));
    setStage("final");
  };

  const handleResetClick = () => {
    setGirls(allGirls);
    setStage("hello");
  };

  useClickOutside(isOpen, dropRef, () => setIsOpen(false));

  return (
    <div className="content">
      <img className={`robot`} src={robotImg} alt="robot" />
      <img className={`flowersTop`} src={flowersTop} alt="flowersTop" />
      <img
        className={`flowersBottom`}
        src={flowersBottom}
        alt="flowersBottom"
      />
      <div className="mainBlock">
        <p className="title">Ничего себе!</p>
        <div className="text">
          <p>Ты настолько уникальна, что даже мне не под силу узнать тебя...</p>
          <p>Признаю поражение и прошу тебя сказать, кто же ты на самом деле</p>
        </div>
        <div className="controls">
          <div className="dropWrapper">
            <input
              className="input"
              onChange={handleChange}
              placeholder={"Введи свое имя и фамилию"}
              value={inputValue}
            />
            {isOpen && (
              <div className={"dropContaner"} tabIndex={0} ref={dropRef}>
                <ScrollArea maxHeight={"180px"}>
                  {hints.map((hint) => {
                    const { name } = hint;
                    const renderName = name?.split(" ").reverse().join(" ");
                    return (
                      <div
                        className={`dropdownItem`}
                        onClick={handleNameClick}
                        data-name={name}
                        key={name}
                      >
                        {renderName}
                      </div>
                    );
                  })}
                </ScrollArea>
              </div>
            )}
          </div>
          <button className="buttonPrimary" onClick={handleSubmitClick}>
            Продолжить
          </button>
        </div>
        <button className="button buttonCentered" onClick={handleResetClick}>
          Не скажу, начать заново
        </button>
      </div>
    </div>
  );
};

export default NameInput;
