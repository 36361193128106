import { useState } from "react";
import robotImg from "../assets/image/robotHeart.png";
import flowersTop from "../assets/image/sorokaTop.png";
import flowersBottom from "../assets/image/sorokaBottom.png";

const departmentQuestions = {
  "Отдел разработки": [
    "Соберешь shared с закрытыми глазами?",
    'Знаешь, что скрывается за словами "gulp-builder"?',
    "Ты из отдела разработки?",
  ],
  "Отдел дизайна": [
    "Играла со шрифтами и ïðîèãðàëà?",
    "Вторник в четверг это норм?",
    "Ты дизайнер?",
  ],
  "Отдел управления проектами": [
    "Знаешь, как выровнять плановую рентабельность любого проекта? ",
    "Найдешь ресурсы для внеплановой отрисовки клиента?",
    "Ты из Проектного офиса?",
  ],
  HR: [
    "Помогаешь компании набирать лучших людей? ",
    "Организуешь любые корпоративы любой сложности с широко закртыми глазами?",
    "Ты из отдела HR?",
  ],
  Финансы: [
    '"Фактура" и "счет-fuckтура" не являются для тебя матерными словами?',
    "Оргнизм твоего отдела состоит на 95% из прекрасного и 5% из мужика с мемами и калькулятором?",
    "Ты из отдела финансов?",
  ],
  OCM: [
    "Понимаешь ли ты что такое эффективный контакт?",
    "Галя, отмена! - это про DrReddy's?",
    "Ты из ОСМ?",
  ],
  "Отдел разработки новых продуктов": [
    "Спринты, дейли, бэклог - слова, которые ежедневно преследуют тебя?",
    "Квадрат - это человек?",
    "Ты из отдела продуктов?",
  ],
  "Медицинский отдел": [
    "Можешь написать текст любой сложности так, чтобы он впечатлил кого угодно?",
    "Знаешь как расшифровывается ДНК?",
    "Ты из медицинского отдела?",
  ],
};

const Department = ({ girls, setGirls, setStage }) => {
  const possibleDepartments = [
    ...new Set(
      girls.map((girl) => {
        return girl.department;
      })
    ),
  ];
  console.log({ possibleDepartments });

  const [departmentNumber, setDepartmentNumber] = useState(0);
  const [questionNumber, setQuestionNumber] = useState(0);

  const handleClick = (event) => {
    const { answer } = event.target.dataset;
    const answerYes = answer === "yes";

    // console.log({ questionNumber }, answerYes, possibleDepartments);
    if (questionNumber === 0) {
      if (answerYes) {
        incrementQuestionNumber();
      } else {
        const nextDepartmentNumber = departmentNumber + 1;
        // console.log({ nextDepartmentNumber }, possibleDepartments);
        if (nextDepartmentNumber < possibleDepartments.length) {
          setDepartmentNumber(nextDepartmentNumber);
          setQuestionNumber(0);
        } else {
          goToDepSelectStage();
        }
      }
    } else if (questionNumber === 1) {
      incrementQuestionNumber();
    } else {
      if (answerYes) {
        goToExperienceStage();
      } else {
        goToDepSelectStage();
      }
    }
  };

  const incrementQuestionNumber = () => setQuestionNumber(questionNumber + 1);
  const goToExperienceStage = () => {
    setGirls(
      girls.filter(
        (girl) => girl.department === possibleDepartments[departmentNumber]
      )
    );
    setStage("experience");
  };
  const goToDepSelectStage = () => setStage("departmentSelect");

  const currentDepartment = possibleDepartments[departmentNumber];
  const question =
    departmentQuestions?.[currentDepartment]?.[questionNumber] || "";

  console.log(
    { question },
    { departmentQuestions },
    { currentDepartment },
    { questionNumber }
  );

  return (
    <div className="content">
      <div className="mainWrapper">
        <img className={`robot`} src={robotImg} alt="robot" />
        <img className={`flowersTop`} src={flowersTop} alt="flowersTop" />
        <img
          className={`flowersBottom`}
          src={flowersBottom}
          alt="flowersBottom"
        />
        <div className="mainBlock">
          <p>{question}</p>
          <div className="controls">
            <button
              className="button buttonYes"
              data-answer={"yes"}
              onClick={handleClick}
            >
              Да
            </button>
            <button
              className="button buttonNo"
              data-answer={"no"}
              onClick={handleClick}
            >
              Нет
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Department;
