import SimpleBar from 'simplebar-react'

import './simplebar.css'

const ScrollArea = ({
  children,
  customRef,
  simplebarRef,
  autoHide = true,
  modificator = '',
  maxHeight,
  onlyVertical,
  styles,
  size = 'L',
}) => {
  return (
    <SimpleBar
      scrollableNodeProps={{ ref: customRef }}
      style={{
        maxHeight: maxHeight || '100%',
        ...styles,
      }}
      autoHide={autoHide}
      ref={simplebarRef}
      className='scrollArea'
    >
      {children}
    </SimpleBar>
  )
}

export default ScrollArea
