import { useState } from "react";
import robotImg from "../assets/image/robotQuestion.png";
import flowersTop from "../assets/image/sorokaTop.png";
import flowersBottom from "../assets/image/sorokaBottom.png";

const planetariy = "Застала стратсессию в планетарии?";
const rest = "Застала ли ты новогодний корпоратив в ресторане рядом с офисом?";
const regata = "Застала ли ты регату?";
const probation = "Ты на испытательном сроке?";
const lastSummer = "Застала ли ты летний корпоратив?";

const Experience = ({ girls, setGirls, setStage }) => {
  const [question, setQuestion] = useState(planetariy);

  const handleClick = (event) => {
    const { answer } = event.target.dataset;
    const answerYes = answer === "yes";
    if (question === planetariy) {
      if (answerYes) {
        setQuestion(rest);
      } else {
        setQuestion(probation);
      }
    } else if (question === rest) {
      if (answerYes) {
        setQuestion(regata);
      } else {
        goToNextStage(0.9, 2.2);
      }
    } else if (question === regata) {
      if (answerYes) {
        goToNextStage(3.8);
      } else {
        goToNextStage(2.2, 3.8);
      }
    } else if (question === probation) {
      if (answerYes) {
        goToNextStage(0, 0.3);
      } else {
        setQuestion(lastSummer);
      }
    } else if (question === lastSummer) {
      if (answerYes) {
        goToNextStage(0.8, 0.9);
      } else {
        goToNextStage(0.3, 0.8);
      }
    }
  };

  const goToNextStage = (thisDateIsBefore, thisDateIsAfter) => {
    const filteredGirls = girls.filter((girl) => {
      const { experience } = girl;
      const isAfter = thisDateIsAfter ? experience <= thisDateIsAfter : true;
      const isBefore = experience >= thisDateIsBefore;
      return isAfter && isBefore;
    });
    setGirls(filteredGirls);
    setStage("name");
  };

  return (
    <div className="content">
      <img className={`robot`} src={robotImg} alt="robot" />
      <img className={`flowersTop`} src={flowersTop} alt="flowersTop" />
      <img
        className={`flowersBottom`}
        src={flowersBottom}
        alt="flowersBottom"
      />
      <div className="mainBlock">
        <p>{question}</p>
        <div className="controls">
          <button
            className="button buttonYes"
            data-answer={"yes"}
            onClick={handleClick}
          >
            Да
          </button>
          <button
            className="button buttonNo"
            data-answer={"no"}
            onClick={handleClick}
          >
            Нет
          </button>
        </div>
      </div>
    </div>
  );
};

export default Experience;
