import { useState } from "react";
import robotImg from "../assets/image/robotQuestion.png";
import flowersTop from "../assets/image/sorokaTop.png";
import flowersBottom from "../assets/image/sorokaBottom.png";

const questiontext = "Тебя зовут";

const Name = ({ girls, setGirls, setStage }) => {
  const [girlNumber, setGirlNumber] = useState(0);

  const handleClick = (event) => {
    const { value, answer } = event.target.dataset;
    const answerYes = answer === "yes";
    if (answerYes) {
      setGirls([currentGirl]);
      setStage("final");
    } else {
      setGirlNumber(girlNumber + 1);
    }
  };

  const currentGirl = girls[girlNumber];
  if (!currentGirl) {
    setStage("nameSelect");
  }
  const question = `${questiontext} ${currentGirl?.name}?`;

  return (
    <div className="content">
      <img className={`robot`} src={robotImg} alt="robot" />
      <img className={`flowersTop`} src={flowersTop} alt="flowersTop" />
      <img
        className={`flowersBottom`}
        src={flowersBottom}
        alt="flowersBottom"
      />
      <div className="mainBlock">
        <p>{question}</p>
        <div className="controls">
          <button
            className="button buttonYes"
            data-answer={"yes"}
            onClick={handleClick}
          >
            Да
          </button>
          <button
            className="button buttonNo"
            data-answer={"no"}
            onClick={handleClick}
          >
            Нет
          </button>
        </div>
      </div>
    </div>
  );
};

export default Name;
