import { useEffect, useState } from "react";
import "./App.css";
import "./assets/styles/fonts.css";
import Common from "./stages/Common";
import Department from "./stages/Department";
import Experience from "./stages/Experience";
import { allGirls } from "./girls";
import NameInput from "./stages/NameInput";
import DepartmentSelect from "./stages/DepartmentSelect";
import Name from "./stages/Name";
import Hello from "./stages/Hello";
import Final from "./stages/Final";

function App() {
  const [girls, setGirls] = useState(allGirls);
  const [stage, setStage] = useState("hello");

  const setNewGirls = (newGirls) => {
    if (newGirls.length === 0) {
      setStage("nameSelect");
    }
    setGirls(newGirls);
  };

  return (
    <div className="App">
      {stage === "hello" && <Hello setStage={setStage} />}
      {stage === "common" && (
        <Common setStage={setStage} girls={girls} setGirls={setNewGirls} />
      )}
      {stage === "department" && (
        <Department setStage={setStage} girls={girls} setGirls={setNewGirls} />
      )}
      {stage === "experience" && (
        <Experience setStage={setStage} girls={girls} setGirls={setNewGirls} />
      )}
      {stage === "name" && (
        <Name setStage={setStage} girls={girls} setGirls={setNewGirls} />
      )}
      {stage === "departmentSelect" && (
        <DepartmentSelect
          setStage={setStage}
          girls={girls}
          setGirls={setNewGirls}
        />
      )}
      {stage === "nameSelect" && (
        <NameInput setStage={setStage} girls={girls} setGirls={setNewGirls} />
      )}
      {stage === "final" && <Final girls={girls} />}
    </div>
  );
}

export default App;
