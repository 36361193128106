import { useEffect } from "react";

const useClickOutside = (isOpen, ref, handler) => {
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const onClick = (event) => {
      console.log("outside", isOpen, ref?.current);
      const target = event.target;
      const clickedInsideElem = ref?.current?.contains(target);
      const itsInsideClick = Boolean(target?.dataset?.itsInsideClick);

      if (!itsInsideClick && !clickedInsideElem) {
        handler(event);
      }
    };

    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("click", onClick);
    };
  }, [isOpen, handler, ref]);
};

export default useClickOutside;
